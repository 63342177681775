import train_icon_1 from '@/assets/train-icon-1.svg';
import train_icon_2 from '@/assets/train-icon-2.svg';
import train_icon_3 from '@/assets/train-icon-3.svg';

import benefits_icon_1 from '@/assets/main/Rocket.svg';
import benefits_icon_2 from '@/assets/main/Dashboard Gauge 2.svg';
import benefits_icon_3 from '@/assets/main/Calculator 1.svg';
import benefits_icon_4 from '@/assets/main/Computer Chip 2.svg';
import benefits_icon_5 from '@/assets/main/Target 3.svg';
import benefits_icon_6 from '@/assets/main/Paint Palette.svg';

import awaits_img_1 from '@/assets/main/awaits-11.png';
import awaits_img_2 from '@/assets/main/awaits-22.png';
import awaits_img_3 from '@/assets/main/awaits-33.png';

import mission_img_1 from '@/assets/main/mission-result-1.svg';
import mission_img_2 from '@/assets/main/mission-result-2.svg';

import who_train_img_1 from '@/assets/main/who-training-1.svg';
import who_train_img_2 from '@/assets/main/who-training-2.svg';
import who_train_img_3 from '@/assets/main/who-training-3.svg';

import how_train_icon_1 from '@/assets/main/how-train-1.svg';
import how_train_icon_2 from '@/assets/main/how-train-2.svg';
import how_train_icon_3 from '@/assets/main/how-train-3.svg';
import how_train_icon_4 from '@/assets/main/how-train-4.svg';

import feedback_img_1 from '@/assets/main/feedback-1.png';
import feedback_img_2 from '@/assets/main/feedback-2.png';
import feedback_img_3 from '@/assets/main/feedback-3.png';

export const navigation_data = [
  {
    id: 1,
    link: 'train-motivates',
    text: 'About us',
  },  
  {
    id: 2,
    link: 'statistics-main',
    text: 'Statistics',
  },
  {
    id: 3,
    link: 'mission-result-main',
    text: 'Our missions',
  },
  {
    id: 4,
    link: 'how-train-main',
    text: 'How to play',
  },
  {
    id: 5,
    link: 'users-feedback-main',
    text: 'Review',
  },
]

export const train_list_data = [
  {
    id: 1,
    icon: train_icon_1,
    h: 'Individual training plan for your brain',
    p: 'Customized brain workouts targeting specific areas',
    style: '',//#FFBDB5
  },
  {
    id: 2,
    icon: train_icon_2,
    h: 'More than 50 exciting games and riddles',
    p: 'Improve your skills by playing fun mini games',
    style: '',//#CEB7FF
  },
  {
    id: 3,
    icon: train_icon_3,
    h: 'Progress and rank statistics',
    p: 'Review progress, assess rankings for a comprehensive overview',
    style: '',//#9DE6FF
  }
]

export const noticable_list_data = [
  {
    id: 1,
    h: '17',
    pref: '+',
    postf: '%',
    p: 'to reaction speed in a 1 week of training',
    color: 'blue',
  },
  {
    id: 2,
    h: '1.6',
    pref: 'x',
    postf: '',
    p: 'to improved concentration in 1 month',
    color: 'red',
  },
  {
    id: 3,
    h: '1.6',
    pref: 'x',
    postf: '',
    p: 'to attention to detail and minutiae in 2 months',
    color: 'violet',
  },
  {
    id: 4,
    h: '19',
    pref: '+',
    postf: '%',
    p: 'to reaction speed in a 1 week of training',
    color: 'orange'
  },
]

export const benefits_list_data = [
  {
    id: 1,
    icon: benefits_icon_1,
    h: 'Cognitive speed',
    p: 'Improve your reaction time, efficiency and productivity',
    style: 'rocket',
  },
  {
    id: 2,
    icon: benefits_icon_2,
    h: 'Visual processing speed',
    p: 'Accelerate your information processing speed',
    style: 'speed',
  },
  {
    id: 3,
    icon: benefits_icon_3,
    h: 'Accurate calculation',
    p: 'Perform calculations more quickly and accurately',
    style: 'calc',
  },
  {
    id: 4,
    icon: benefits_icon_4,
    h: 'Working memory',
    p: 'Accelerate your information processing speed',
    style: 'memory',
  },
  {
    id: 5,
    icon: benefits_icon_5,
    h: 'Focus',
    p: 'Focus on one object and ignore nosy thoughts',
    style: 'focus',
  },
  {
    id: 6,
    icon: benefits_icon_6,
    h: 'Creativity',
    p: 'Use your imagination to generate solutions',
    style: 'creativity',
  }
]

export const statistics_data = [
  {
    id: 1,
    color: 'blue-h', //#48C1F8
    border: 'blue-b',
    h: 'Сoncentration',
  },
  {
    id: 2,
    color: 'red-h', //#FF5151
    border: 'red-b',
    h: 'Memory',
  },
  {
    id: 3,
    color: 'yellow-h', //#F9B300
    border: 'yellow-b',
    h: 'Attention to detail',
  },
  {
    id: 4,
    color: 'brown-h', //#EF6E26
    border: 'brown-b',
    h: 'Focus on objects',
  },
  {
    id: 5,
    color: 'purple-h', //#B56CE4
    border: 'purple-b',
    h: 'Reaction speed',
  },
  {
    id: 6,
    color: 'green-h', //#4DB601
    border: 'green-b',
    h: 'Logical thinking',
  },
  {
    id: 7,
    color: 'pink-h', //#FF85D0
    border: 'pink-b',
    h: 'Mental arithmetic',
  },
]

export const train_users_data = [
  {
    id: 1,
    h: '5',
    pref: '',
    postf: 'minutes',
    p: 'on average spend studying every day',
    color: 'red-train-p', //#FF6433
    border: 'red-train-b', //#FFCEBF
  },
  {
    id: 2,
    h: '67',
    pref: '',
    postf: '%',
    p: "there's a training session every day",
    color: 'yellow-train-p',//#FFC107
    border: 'yellow-train-b',//#FFE598
  },
  {
    id: 3,
    h: '42',
    pref: '',
    postf: '%',
    p: 'are moving to a new level of cognitive abilities',
    color: 'green-train-p',//#66CF00
    border: 'green-train-b',//#B9F282
  },
]

export const what_awaits_data = [
  {
    id: 1,
    img: awaits_img_1,
    h: 'Individual training plan for your brain',
    p: 'Customized brain workouts targeting specific areas',
    style: 'pink-awaits-b', //#E4C8FF
  },
  {
    id: 2,
    img: awaits_img_2,
    h: 'More 50 exciting games and puzzles',
    p: 'On average spend studying every day',
    style: 'purple-awaits-b',//#C4AFFF
  },
  {
    id: 3,
    img: awaits_img_3,
    h: 'Progress and rank statistics',
    p: 'Track your skills and collect awards',
    style: 'blue-awaits-b',//#A6C2FF
  },
]

export const mission_result_data = [
  {
    id: 1,
    img: mission_img_1,
    h: 'Main mission',
    p: "We're here to develop the best education in the world and make it universally available. Our global team works together to make education fun, interesting, and effective for anyone who wants to studty, wherever they are.",
  },
  {
    id: 2,
    img: mission_img_2,
    h: 'Result guarantee',
    p: 'Game trainings are based on the methods of neuropsychologists and experts in the field of human cognitive abilities in the field of human cognitive abilities, which have proven their effectiveness in the course of many experiments and scientific works',
  },
]

export const who_train_data = [
  {
    id: 1,
    img: who_train_img_1,
    h: 'Younger age',
    p: {
      item1: 'Improving the learning process in educational institutions',
      item2: 'Prevention of attention deficit disorder (ADD)',
    }
  },
  {
    id: 2,
    img: who_train_img_2,
    h: 'Middle age',
    p: {
      item1: 'Increase personal productivity and efficiency',
      item2: 'Make fewer mistakes and omissions at work',
    }
  },
  {
    id: 3,
    img: who_train_img_3,
    h: 'Elderly age',
    p: {
      item1: 'Keep your mind sharp and clear longer',
      item2: 'Prevention diseases caused by decreased intellectual load',
    }
  },
]

export const how_train_data = [
  {
    id: 1,
    icon: how_train_icon_1,
    h: 'Assess your abilities',
    p: 'Improve your reaction time, efficiency and productivity',
    style: 'how-train-blue',//#CDDDFF
  }, 
  {
    id: 2,
    icon: how_train_icon_3,
    h: 'Track your progress',
    p: 'Stay up to date with your current level',
    style: 'how-train-skin',//#FFBDB5
  },
  {
    id: 3,
    icon: how_train_icon_2,
    h: 'Do a daily workout & play games',
    p: 'Play mini-games to improve your abilities',
    style: 'how-train-sea',//#B5F8FF
  },
  {
    id: 4,
    icon: how_train_icon_4,
    h: 'Earn points and increase ranks',
    p: 'Increase your gaming level by earning points',
    style: 'how-train-yellow',//#FFF0A3
  },
]

export const feedback_data = [
  {
    id: 1,
    avatar: feedback_img_1,
    name: 'Olivia Anderson',
    text: 'Brainy Train is my go-to for mental workouts! The diverse puzzles keep me hooked, and the gradual difficulty increase ensures a challenging yet rewarding experience. A must-try for anyone looking to boost brainpower on the go!',
  },
  {
    id: 2,
    avatar: feedback_img_2,
    name: 'Benjamin Mitchell',
    text: "Impressed by Brainy Train's seamless blend of entertainment and brain training. The intuitive interface and captivating challenges make it enjoyable. I've noticed a positive impact on my memory and problem-solving skills.",
  },
  {
    id: 3,
    avatar: feedback_img_3,
    name: 'Emma Taylor',
    text: 'Brainy Train is a gem! It turned my gaming time into a productive brain boost. The puzzles are both entertaining and mentally stimulating. The graphics are vibrant, and the daily challenges keep me coming back for more.',
  },
]